import {FormDrawer} from "@app/components/FormDrawer/FormDrawer";
import {eSet, PaymentTypes, set} from "@app/lib/utils";
import {Button, Form, Input, Select} from "antd";
import {FormComponentProps} from "antd/lib/form";
import {toJS} from "mobx";
import {observer} from "mobx-react";
import * as React from "react";
import ReactJson from "react-json-view";
import {transactionFormStore} from "@pages/transactions/TransactionForm/TransactionFormStore";
import _ from "lodash";
import {brandFormStore} from "@pages/brands/BrandForm/BrandFormStore";
import Spin from "antd/lib/spin";

@observer
class TransactionFormBare extends React.Component<FormComponentProps> {

    public componentDidMount(): void {
        transactionFormStore.formUtils = this.props.form;
    }

    public handleSubmit = (e: React.FormEvent): void => {
        e.preventDefault();
        this.props.form.validateFields((err: Error | undefined) => {
            if (!err) {
                // noinspection JSIgnoredPromiseFromCall
                transactionFormStore.save();
            }
        });
    }

    // noinspection DuplicatedCode
    public render(): JSX.Element {
        const transaction = transactionFormStore.data;

        const {getFieldDecorator} = this.props.form;
        return <>
            <FormDrawer store={transactionFormStore}>
                <Form layout="vertical" onSubmit={this.handleSubmit}>

                    <Form.Item label="Brand">
                        {getFieldDecorator("brand_id", {
                            rules: [{required: true}]
                        })(<Select placeholder="Select brand" showSearch
                                   onChange={transactionFormStore.setBrandId as any}
                                   optionFilterProp="children"
                                   notFoundContent={transactionFormStore.fetching ? <Spin size="small" /> : null}
                                   style={{width: "100%"}}
                                   onSearch={transactionFormStore.onFetchClient}
                                   filterOption={false}>
                            {transactionFormStore.brands.map(b => <Select.Option key={b.id} value={b.id}>
                                {b.name}
                            </Select.Option>)}
                        </Select>)}
                    </Form.Item>

                    <Form.Item label="Type">
                        <Select defaultValue={_.get(transaction, "method")} onChange={set(transaction, "method")}
                                placeholder="Select method">
                            {PaymentTypes.map(pType => <Select.Option key={pType} value={pType}>
                                {pType}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>

                    <Form.Item label="Prices">
                        <Select defaultValue={transaction.month} onChange={set(transaction, "month")} placeholder="Select month">
                            {Array.from({length: 12}, (_, i) => i + 1).map(n => <Select.Option key={n} value={n}>
                                {n} Month
                            </Select.Option>)}
                        </Select>
                    </Form.Item>


                    <Form.Item label="Amount">
                        {getFieldDecorator("amount", {
                            rules: [{required: true}],
                            initialValue: transaction.amount
                        })(
                            <Input placeholder="Amount" min={1} name={"amount"} onChange={eSet(transaction, "amount")}/>
                        )}
                    </Form.Item>

                    <Form.Item label="Note">
                        {getFieldDecorator("note", {rules: [{required: false}]})(
                            <Input.TextArea placeholder="Note" onChange={eSet(transaction, "note")}/>
                        )}
                    </Form.Item>

                    <Button type="primary" loading={transactionFormStore.loading}
                            htmlType="submit" style={{marginRight: 5}}>
                        Save
                    </Button>
                    <Button type="default" onClick={transactionFormStore.hide} htmlType="button">Cancel</Button>

                    <ReactJson src={toJS(transaction)} collapsed={1} name={transactionFormStore.dataName}/>

                </Form>
            </FormDrawer>
        </>;
    }
}

export const TransactionForm = Form.create()(TransactionFormBare);
